/* stylelint-disable */
/** @define footer **/
.footer {
    padding: 65px 0 0;
    background: $goblin;
    position: relative;
    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 53px;
        content: '';
        height: 55px;
        background: $elephant;
        @include media-breakpoint-down(md) {
            height: 45px;
        }
    }

    .container {
        position: relative;
        z-index: 9;
    }
}
.footer_logo {
    width: 147px;
    margin: -46px 0 13px 40px;
    @include media-breakpoint-down(md) {
        width: 100px;
        margin: -30px auto 10px;
    } 
    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.footer_box .holder {
    position: relative;
    margin-left: -17px;
    @include media-breakpoint-down(md) {
        margin: 0;
    }
}

.footer_box .holder2 {
    position: relative;
    margin-left: 13px;
    @include media-breakpoint-down(md) {
        margin: 0;
    }
    .footer_headings {
        margin-bottom: 28px;
    }

}

.footer_box {
    @include media-breakpoint-down(md) {
        margin-bottom: 15px;
    }
}

.footer_box .holder3 {
    position: relative;
    margin-left: 37px;
    @include media-breakpoint-down(md) {
        margin: 0;
    }
    .footer_headings {
        margin-bottom: 28px;
    }
}

.footer_headings {
    font-size: 28px;
    color: $white;
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
        font-size: 22px;
        line-height: 28px;
    }
    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 20px !important;
    }
    @include media-breakpoint-down(sm) {
        margin: 0 !important;
    }
}
.footer_box ul {
    padding: 0;
    margin: 0 8px 17px 0; 
    list-style: none;
    font-size: 21px;
    line-height: 24px;
    font-family: $chaparral-pro;
    @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 20px;
    }
}
.footer_box li {
    margin-bottom: 3px;
}
.footer_box li a {
	color: #fff;
    padding: 0;
}
.footer_box li a:hover {
    color: #f7e41e;
}
.footer_social {
    text-align: center;
}
.footer_social li a {
    font-size: 35px;
}

.footer_social li {
    display: inline-block;
    vertical-align: middle;
    margin: 3px 5px 0;
    &:last-child {
        margin-top: 0;
        width: 84px;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}
.logo_slogon {
    font-size: 27px;
    line-height: 30px;
    color: #fff;
    font-style: italic;
    text-align: center;
    @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 23px;
    }
    @include media-breakpoint-down(md) {
        br {
            display: none;
        }
    }
}
.logo_slogon span {
    display: block;
    margin-top: 10px;
}
.copyright {
    background: rgba(0,0,0,.3);
    text-align: center;
    font-family: $chaparral-pro;
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    padding: 27px 0 24px;
    margin-top: 72px;
    @include media-breakpoint-down(md) {
        margin-top: 0;
        font-size: 14px;
        line-height: 20px;
    }

    a {
        color: #fff;
        &:hover {
            color: #f7e41e;
        }
    }
}
.copyright p {
    margin: 0;
}
.copyright ul {
    margin: 0;
    padding: 0;
    display: inline-block;
}
.copyright li {
    display: inline-block;
    color: #fff;
    padding: 0 0 0 10px;
    position: relative;
}
.copyright li:before {
    position: absolute;
    content: '.';
    font-size: 32px;
    top: 0;
    left: 0px;
    bottom: 0;
    line-height: 10px;
}
.copyright li a
{
	color: #fff;
}
.copyyear {
    display: block;
    width: 100%;
    opacity: 0.3;
    padding: 11px 57px 0 0;
    @include media-breakpoint-down(sm) {
        padding-right: 0;
    }
}
.footer_headings a {
    color: #fff;
    &:hover {
        color: hsl(196, 84%, 58%);
    }
}
