/* stylelint-disable */
body {
  min-width: 320px;
  font-size: 20px;
  line-height: 1.8;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }

}

/** @define anchor **/
a {
  transition: all 0.4s ease;
  text-decoration: none;
}

/** @define h1 **/
h1,
.h1 {
  margin: 0 0 20px;
  @include media-breakpoint-down(md) {
    font-size: 36px;
    line-height: 36px;
  }
}

/** @define h2 **/
h2,
.h2 {
  margin: 0 0 40px;
  @include media-breakpoint-down(lg) {
    font-size: 22px;
    margin: 0 0 25px;
  }
}

/** @define h2 **/
h3,
.h3 {
  letter-spacing: -0.4px;
  margin: 0 0 7px;
  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

/** @define p **/
p {
  margin: 0 0 20px;
  @include media-breakpoint-down(sm) {
    margin: 0 0 15px;
  }
}

.img {
  @include img-style;
}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.main {
  position: relative;
  display: block;
}

.d-hidden {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}
.m-hidden {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.btn-primary {
  @include font-size( 28px , 57px);
  background: $picton-blue;
  font-family: $chaparral-pro;
  width: 194px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  color: $white;
  padding: 0;
  border-radius: 0;
  border: 0;
  display: block;
  @include media-breakpoint-down(lg) {
    @include font-size( 20px , 40px);
    height: 40px;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
  }

  .ico {
    display: inline-block;
    vertical-align: middle;
    background: url(../img/arrow-right.png) no-repeat;
    width: 10px;
    height: 10px;
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
  }

  &:hover {
    background: $elephant;
  }
}

.bg-video-holder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-size: cover;
}

.bg-video-holder .bg-video {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity .3s ease;
  pointer-events: none;
}

.video-active .bg-video {
   opacity: 1;
}

.header input[type="text"]::placeholder,
.header input[type="email"]::placeholder {
  color: #f7f7f7;
}
.header input[type="text"]:-ms-input-placeholder,
.header input[type="email"]:-ms-input-placeholder { 
  /* Internet Explorer 10-11 */
   color: #f7f7f7;
}

.header input[type="email"]::-ms-input-placeholder,
.header input[type="text"]::-ms-input-placeholder { 
  /* Microsoft Edge */
   color: #f7f7f7;
}
.nav-form label {
  display: none !important;
}
.nav-form ul {
  padding: 0 !important;
  @media (min-width: 992px) {
    display: flex !important;
  }
}
.nav-form form {
  @media (min-width: 992px) {
    display: flex;
  }
  justify-content: space-between;

  & .validation_error {
    display: none !important;
  }
  & .validation_message {
    font-weight: 400 !important;
  }
}
.nav-form ul li {
  padding: 0 12px !important;
  margin: 0 !important;
}
.nav-form .gform_wrapper {
  margin: 0 !important;
  padding: 0 !important;

  & input {
    padding: 0 !important;
    font-size: 21px !important;
    min-width: 131px;
  }
  & .ginput_container {
    margin-top: 0px !important;
  }
}
.nav-form .gform_body {
  margin: 0 12px 0 -12px;
  @media (max-width: 992px) {
    margin: 0;
  }
}
.nav-form .gform_footer {
  clear: none !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 130px;
  @media (max-width: 992px) {
    display: table;
    margin: 0 auto !important;
    float: none;
  }

  & input {
    width: 131px !important;
    font-size: 21px;
  }
}

body .gform_wrapper .top_label div.ginput_container {
  margin-top: 0 !important;
}
.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){ max-width: 100% !important ; width: 100% !important;}
.gform_wrapper li.gfield.gfield_error, 
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning{ border: none !important; background:none !important;}
.gform_wrapper li.gfield_error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), 
.gform_wrapper li.gfield_error textarea{background:#ff8c8c !important;}
.gform_wrapper .field_description_below .gfield_description{padding: 0 !important}
.gfield_error {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 15px !important;
}
.gform_wrapper ul.gform_fields li.gfield {
  margin-bottom: 16px !important;
  margin-top: 0 !important;
}
.instruction.validation_message {
  display: none;
}
.nav-form form .validation_message {
  line-height: 18px !important;
}
.gfield_description.validation_message {
  height: 8px;
  font-weight: 300;
}
.validation_error {
  border: none !important;
}
.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label, .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container {
  margin-top: 0 !important;
}
.alignleft {
  float: left;
  margin: 0 25px 0 0;
  @include media-breakpoint-down(sm) {
    float: none;
    margin: 20px auto;
    width: 100%;
  }
}
p:empty {
  display: none;
}
.bg-video-holder video {
  width: 100%;
  height: auto;
}
@include media-breakpoint-down(sm) {
  .mob-hidden {
    display: none;
  }
}
@include media-breakpoint-up(md) {
  .footer_menus .nav-link {
    display: table;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
  .nav-form .gform_footer {
    margin-right: 39px !important;
  }
}
.author-info .author-avatar {
  float: left;
  margin-right: 15px;
}
.author-info .author-description {
  margin-top: -6px;

}
.dropdown li {
  position: relative;
}
.dropdown li ul {
  display: none !important;
}
.dropdown li:hover ul {
  display: block !important;
}
.dropdown ul ul {
    left: 100%;
    padding: 0 !important;
    top: -2px !important;
}
.content-area ul {
  overflow: hidden;
}
.content-area h3 {
    color: hsl(117, 37%, 40%) !important;
}
.page-id-104 .content-area p {
  margin-bottom: 20px;
}
.gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
  padding: 2px 4px 3px
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .gform_body select {
      padding: 6px 5px 5px !important;
      margin-top: 0 !important;
    }
  .gform_wrapper.gf_browser_chrome .gfield_checkbox li input, .gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox], .gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] {
    margin-top: 2px !important
  }  
  .gform_wrapper.gf_browser_chrome .gfield_checkbox li input, .gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox] {
    position: relative;
    top: -2px;
  }
}
input[type="submit"]:hover {
  cursor: pointer !important;
}
.gform_wrapper input[type="checkbox"] {
  margin-top: -1px;
}