/* stylelint-disable */

@include media-breakpoint-down(md) {
  #menu-header-menu {
    margin:0;
    padding: 0;

    ul {
      display: none;
      position: static !important;
      width: 100% !important;
      float: none;
      padding: 0;
    }
  }

  #menu-header-menu-1 {
    margin:0;
    padding: 0;

    ul {
      display: none;
      position: static !important;
      width: 100% !important;
      float: none;
      padding: 0;
    }
  }
}
@include media-breakpoint-down(md) {
  .nav .nav-area a.dropdown-toggle {
    background: #fff !important;
  }
}
.dropdown ul {
  background: #144355;
}
.dropdown-menu {
  transform: translate3d(0px, 0px, 0px) !important;
}
@include media-breakpoint-up(md) {
  .dropdown ul {
    top: 32px !important;
  }  
}
@include media-breakpoint-down(md) {
  .dropdown ul ul {
      background: rgba(255,255,255,0.1);
      display: block !important;
  }
}
@supports (-ms-ime-align:auto) {
  .dropdown ul ul {
    overflow: hidden;
  }
}
