/** @define top-section **/
.top-section {
  position: relative;
  height: 100vh;
  @include media-breakpoint-down(sm) {
    min-height: 460px;
    max-height: 460px;
  }
}

/** @define header **/
.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  @include media-breakpoint-up(md) {
    min-height: 100px;
  }

  @include media-breakpoint-down(md) {
    background: $white;
  }

  &__holder {
    position: relative;
    padding-left: 178px;
    @include media-breakpoint-down(md) {
      padding: 15px 0;
    }
  }
}

/** @define logo **/
.logo {
  width: 147px;
  position: absolute;
  left: 17px;
  top: 10px;
  z-index: 99;
  @include media-breakpoint-down(md) {
    position: static;
    width: 90px;
    margin-left: 5%;
  }
  @include media-breakpoint-down(sm) {
    width: 70px;
  }
}

/** @define video-section **/
.video-section {
  position: relative;
  background-size: cover;
}
/* stylelint-disable */
@include media-breakpoint-down(lg) {
  .header .container {
    max-width: 100%;
  }
}
@include media-breakpoint-down(sm) {
  .home .main {
    padding-top: 66px;
  }
}
