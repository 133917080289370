/** @define top-nav **/
.top-nav {
  position: relative;
}

/** @define top-nav-list **/
.top-nav-list {
  @include listreset;
  @include font-size( 20px , 27px);
  display: inline-flex;
  flex-wrap: wrap;
  font-weight: 600;

  &__li {
    margin: 0 0 0 43px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
      margin-left: 16px;
    }

    &:first-child {
      margin-left: 16px;
    }
  }

  /* stylelint-disable */
  li {
    &:nth-child(4) {
      margin-left: 30px;
      @include media-breakpoint-down(lg) {
        margin-left: 16px;
      }
      a {
        padding: 10px 24px;
        background: $picton-blue;
        @include media-breakpoint-down(md) {
          padding: 10px;
        }

        &:hover {
          background: $elephant;
          color: $white;
        }
      }
    }
    &:last-child {
      margin-left: 23px;
      @include media-breakpoint-down(lg) {
        margin-left: 16px;
      }
      a {
        padding: 10px 18px;
        background: $picton-blue;
        @include media-breakpoint-down(lg) {
          padding: 10px;
        }

        &:hover {
          background: $elephant;
          color: $white;
        }
      }
    }
  }

  a {
    display: block;
    color: $white;

    &:hover {
      color: $ripe-lemon;
    }
  }
  /* stylelint-enable */

}

/** @define nav-area **/
.nav-area {
  position: relative;
  background: $elephant;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  @include media-breakpoint-down(md) {
    display: block;
    height: auto;
    background: none;
  }

  &__list {
    @include listreset;
    @include font-size( 0 , 0);

    /* stylelint-disable */
    >* {
      @include font-size( 28px , 32px);
      @include media-breakpoint-down(md) {
        @include font-size( 15px , 19px);
      }
    }

    &::before {
      background: $elephant;
      content: '';
      position: absolute;
      left: -9999px;
      right: -9999px;
      top: 0;
      bottom: 0;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    > li {
      display: inline-block;
      vertical-align: top;
      padding: 0 16px;
      position: relative;
      margin: 0 17px 0 0;
      @include media-breakpoint-down(md) {
        display: block;
      }

      > a {
        display: block;
        color: $ripe-lemon;
        padding: 0;

        &:hover {
          color: $white;
        }
      }
    }
  }
}


/** @define socialicons **/
.socialicons {
  @include listreset;
  display: flex;
  position: relative;
  z-index: 5;
  padding: 0 5px 0 0;

  &__ico {
    margin: 4px 0 0 9px;
    display: block;
    font-size: 30px;
    color: $white;

    /* postcss-bem-linter: ignore */
    &:hover {
      color: $ripe-lemon;
    }
  }
}

/** @define nav-form-area **/
.nav-form-area {
  background: $goblin;
  padding: 19px 15px 23px 127px;
  @include media-breakpoint-down(md) {
    padding: 19px 15px 13px;
  }

  &__title {
    @include font-size(25px , 27px);
    position: absolute;
    font-weight: 600;
    left: 20px;
    top: 12px;
    color: $white;
    text-align: center;
    width: 70px;
    @include media-breakpoint-down(md) {
      position: static;
      width: 100%;
      display: block;
      margin: 0 0 20px;
    }
  }

  &.col-md-10 {
    max-width: 81.333%;
    flex: 0 0 81.333%;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  form {
    position: relative;
    padding: 0 5px 0 0;

    ul {
      @include listreset;
      display: flex;
      justify-content: space-between;
      position: relative;
      margin: 0 -12px;
      @include media-breakpoint-down(md) {
        display: block;
        margin: 0;
      }
    }

    li {
      padding: 0 12px;
      @include media-breakpoint-down(md) {
        padding: 0 0 20px;
        margin: 0 0 10px !important;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="tell"] {
      @include font-size(21px , 26px);
      width: 100%;
      border: 0;
      border-bottom: 4px solid $white;
      font-weight: 300;
      color: $white;
      background: none;
      outline: none;
      @include media-breakpoint-down(md) {
        text-align: center;
        border-bottom-width: 1px;
      }

    }
    input[type="submit"] {
      @include font-size(21px , 40px);
      width: 130px;
      height: 40px;
      background: $elephant;
      color: $white;
      border: none;
      padding: 0;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
      @include media-breakpoint-down(md) {
        margin: 20px auto 0;
        display: block;
      }

      &:hover {
        background: $picton-blue;
      }
    }
  }
}

/** @define nav-opener **/
.nav-opener {
  @include size(28px, 22px);
  border-top: 4px solid $elephant;
  transition: all 0.4s ease;
  position: absolute;
  right: 5%;
  top: 40px;
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
  /* postcss-bem-linter: ignore */
  .nav-active & {
    border: 0;
  }

  /* postcss-bem-linter: ignore */
  &::after {
    @include size(28px, 4px);
    transition: all 0.4s ease;
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    background: $elephant;
    /* postcss-bem-linter: ignore */
    .nav-active & {
      transform: rotate(45deg);
      top: 8px;
    }
  }

  /* postcss-bem-linter: ignore */
  &::before {
    @include size(28px, 4px);
    transition: all 0.4s ease;
    content: '';
    position: absolute;
    left: 0;
    top: 14px;
    background: $elephant;
    /* postcss-bem-linter: ignore */
    .nav-active & {
      transform: rotate(-45deg);
      top: 8px;
    }
  }
}

/** @define nav-drop **/
.nav-drop {
  position: relative;
  @include media-breakpoint-down(md) {
    transition: all 0.4s ease;
    position: absolute;
    top: 100%;
    left: -15px;
    right: -15px;
    overflow: hidden;
    max-height: 0;
    z-index: 99;
    /* postcss-bem-linter: ignore */
    .nav-active & {
      max-height: 4000px;
    }
  }
}

/** @define nav **/
.nav {
  position: relative;
  display: block;
  @include media-breakpoint-down(md) {
    background: $white;
    transition: all 0.4s ease;
    transform: translateY(-100%);
    .nav-area,
    .top-nav {
      ul {
        display: block;
        text-align: center;
        font-size: 15px !important;
        line-height: 19px !important;
        li {
          display: block !important;
          padding: 0 !important;
          margin: 0 !important;
          border-bottom: 2px solid $elephant;
        }
        a {
          display: block;
          padding: 12px 15px;
          color: $elephant !important;
          background: none !important;
          font-weight: 600;
          &:hover {
            background: $elephant !important;
            color: $white !important
          }
        }
        ul {
          a {
            color: $white !important;
            &:hover {
              background: $white !important;
              color:  $elephant !important
            }
          }
        }
      }
      .socialicons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        border-bottom: 2px solid $elephant;
        li {
          display: inline-block !important;
          border: none;
          padding: 0 10px;
        }
        a {
          padding: 0 !important;
          background: none !important;
          &:hover {
            background: none !important;
            color: $fruit-salad !important;
          }
        }
      }
    }
    .nav-active & {
      transform: translateY(0);
    }
  }
}

/** @define dropdown **/
.dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  background: $elephant;
  z-index: 999;
  @include media-breakpoint-down(md) {
    width: 100%;
    right: 0;
    position: relative;
  }

  ul {
    @include listreset;
    display: none;
    padding: 14px 0 0;
    font-size: 20px;
    line-height: 24px;

    ul {
      left: 100%;
      top: 0;
    }
  }

  li {
    border-bottom: 1px solid rgba($white , 0.2);
    margin: 0;
    padding: 0;
    a {
      color: $white !important;
      display: block;
      padding: 10px 15px;
      &:hover {
        background: $picton-blue;
      }
    }
  }  
}

@media (min-width: 992px) {
  .dropdown-menu {
    display: none;
    transition: all 0.4s ease;
  }
  .dropdown-toggle {
    &::after { display: none;}
  }
}

.nav-area__list {
  li {
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
}

.dropdown-menu {
  padding: 0;
  margin: 0;
  border: 0;
}

.dropdown-toggle {
  &::after {
    border-top: 0.5em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    margin-left: 10px;
    position: relative;
    top: 2px;
  }
}
.navigation.pagination .screen-reader-text {
  display: none;
}
@include media-breakpoint-up(lg) {
  body:not(.home) .top-nav {
    @include media-breakpoint-up(lg) {
      background: #b2cdae;
    }
  }
  body:not(.home) .top-nav:before {
    content: "";
    height: 100%;
    margin: 0 -9999px;
    padding: 0 9999px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    background: #b2cdae;
  }
}
body:not(.home) .nav-form {
  width: 100%;
  @include media-breakpoint-up(md) {
    position: absolute;
  }
}
body:not(.home) .header {
  position: static;
}
.footer_menus li.current-menu-item a {
  color: #f7e41e;
}
.nav-area__list li.current-page-ancestor a {
  color: $white;
}
.current-menu-ancestor .dropdown-menu li.current-menu-item a {
  background: hsl(196, 84%, 58%);
}

.hidden-mobile {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.hidden-desktop {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}